.sm {
    width: 140px;
    height: 24px;
}

.lg {
    width: 210px;
    height: 36px;
}

.xl {
    aspect-ratio: 1440/241;
    height: 100%;
    width: auto;
}

.sm,
.lg,
.xl {
    cursor: pointer;
    display: block;
}
