.group {
    margin-bottom: 14px;
}

.inputGroup {
    display: flex;
    background: #ebebeb;
    border-radius: 3px;
    border-bottom: 2px solid #e1e1e1;
}

.inputGroup :global(.mdi-icon) {
    margin: 8px 5px 0 10px;
    color: #003c4c;
}

.input {
    width: 100%;
    max-width: 400px;
    border: 0;
    border-radius: 0px 3px 3px 0;
    background: #ebebeb;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    line-height: 1.5;
    padding: 9px 5px;
    -webkit-appearance: none;
    transition: all 0.3s ease 0s;
}

.input:hover,
.input:focus {
    outline: none;
}

.input::placeholder {
    color: #a9a9a9;
}

.submit {
    width: 100%;
    padding: 9px 28px;
    color: #fff;
    background: #f30039;
    font-size: 14px;
    font-weight: 600;
    border-radius: 3px;
    border: 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
    transition: all 0.3s ease;
    margin-bottom: 20px;
}

.submit:hover {
    box-shadow: 0 5px 6px rgba(0, 0, 0, 0.16);
}

.submitWide {
    composes: submit;
    width: 100%;
}

.error {
    color: #ff1739;
    font-size: 13px;
    margin-top: 6px;
    margin-left: 42px;
    line-height: 17px;
}

.row {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.row .group {
    flex-grow: 1;
}

.reactivateBtn {
    clear: both;
    display: block;
    margin-top: 6px;
    color: #ff1739;
    font-weight: 600;
}

@media (min-width: 560px) {
    .row {
        flex-direction: row;
        margin-bottom: 0;
    }
}
