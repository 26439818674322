.wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.inner {
    width: 100%;
    max-width: 410px;
    text-align: center;
}

.logo,
.logoCentered {
    margin-bottom: 60px;
}

.logoCentered img {
    width: 210px;
    height: 36px;
    margin-left: auto;
    margin-right: auto;
}

.header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 25px;
}

.headerText {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 24px;
}

.headerTextSmall {
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
}

.headerTextSmallest {
    margin-top: 20px;
    font-size: 12px;
    font-weight: 300;
}

.link,
.success {
    color: #ff1739;
    font-weight: 600;
    text-decoration: none;
}

.form {
    width: 100%;
}

@media (min-width: 768px) {
    .wrapper {
        padding: 60px;
        min-height: 100vh;
        height: auto;
        flex-direction: row;
    }
}
