.checkbox {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 12px;
    line-height: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    user-select: none;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    border-radius: 2px;
    background: #ebebeb;
}

.checkbox:hover input ~ .checkmark {
    background: #e1e1e1;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: block;
}

.checkbox input:checked ~ .checkmark {
    background: #e1e1e1;
}

.checkbox .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid #003c4c;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
