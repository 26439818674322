.form {
}

.header {
    display: flex;
    margin-bottom: 20px;
    line-height: 22px;
}

.headerLeft {
    line-height: 30px;
    font-size: 26px;
    color: #f30039;
    font-weight: 600;
    white-space: nowrap;
    border-right: 1px solid #dcdcdc;
    padding-right: 10px;
    margin-right: 10px;
}

.headerRight {
    font-size: 12px;
    line-height: 15px;
    color: #1c1c1c;
}

.headerRight > a {
    color: #f30039;
    text-decoration: none;
    font-weight: 600;
}

.line {
    height: 1px;
    background: #dbdbdb;
    margin-bottom: 20px;
}

.forgotLink {
    font-size: 12px;
    font-weight: 600;
    color: #f30039;
    text-decoration: none;
}

.forgotLink:hover {
    text-decoration: underline;
}
